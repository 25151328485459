import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="header-container">
        <button className="menu-toggle" onClick={toggleMenu}>
          <span className="menu-icon">☰</span>
        </button>
        <Link to="/" className="logo" onClick={() => window.scrollTo(0, 0)}>
          <img src="/images/chagilLogo.png" alt="Chagil Apps" className="logo-image" />
        </Link>
        <nav className={`nav-menu ${menuOpen ? 'open' : ''}`}>
          <ul>
            <li><a href="#identity-goals" onClick={closeMenu}>Who Are We?</a></li>
            <li><a href="#services" onClick={closeMenu}>Services</a></li>
            <li><a href="#technologies" onClick={closeMenu}>Technologies</a></li>
            <li><a href="#clients" onClick={closeMenu}>Clients</a></li>
            <li><a href="#team" onClick={closeMenu}>Meet Our Team</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;