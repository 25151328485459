import React from "react";
import "./Team.css";

const TeamMember = ({ name, title, description, image }) => (
  <div className="team-member-card">
    <div className="member-image">
      <img src={image} alt={name} />
    </div>
    <h3>{name}</h3>
    <h4>{title}</h4>
    <p>{description}</p>
  </div>
);

const OurTeam = () => {
  const teamMembers = [
    {
      name: "Gil C.",
      title: "CEO / Owner",
   
  description: "Versatile leader with 15+ years in business strategy and tech innovation. Gil combines extensive experience in consulting and service provision with hands-on Flutter and Node.js development skills. His unique approach prioritizes business needs, ensuring tailored solutions that drive client success.",
      image: "/images/gil.jpg",
    },
    {
      name: "Luiza A.",
      title: "Office Manager",
      description:
        "The backbone of our operations, ensuring smooth day-to-day functioning and team coordination.",
      image: "/images/luiza.jpeg",
    },
    {
      name: "Ilya P.",
      title: "Swift & Flutter Developer",
      description:
        "Mobile app wizard, crafting seamless experiences for iOS and cross-platform applications.",
      image: "/images/ilya.jpeg",
    },
    {
      name: "Azimbek M.",
      title: "Full-Stack Developer",
      description:
        "Jack of all trades in tech, from front-end finesse to back-end brilliance and everything in between.",
      image: "/images/azimbek.jpeg",
    },
    // Commented out team members
    // {
    //   name: "Nadia",
    //   title: "UX Expert",
    //   description: "Passionate about creating intuitive and user-centric digital experiences that delight and engage.",
    //   image: "/images/nadia.jpg"
    // },
    // {
    //   name: "Zoe",
    //   title: "UI/UX Expert",
    //   description: "Blending aesthetics with functionality to design interfaces that are both beautiful and user-friendly.",
    //   image: "/images/zoe.jpg"
    // }
  ];

  return (
    <section className="our-team">
      <div className="team-container">
        <h2>Meet Our Team</h2>
        <p>The talented individuals behind our success</p>
        <div className="team-grid">
          {teamMembers.map((member, index) => (
            <TeamMember key={index} {...member} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
