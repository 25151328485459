import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from './components/Header';
import Hero from './components/Hero';
import IdentityGoals from './components/IdentityGoals';
import Services from './components/Services';
import Technologies from './components/Technologies';
import Clients from './components/Clients';
import Team from './components/Team';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import TrustBuilders from './components/TrustBuilders';
import './App.css';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <Header />
          <Helmet>
            <title>My Awesome React App</title>
            <meta name="description" content="This is an awesome React application." />
            <meta name="keywords" content="React, JavaScript, Web App" />
          </Helmet>
          <section id="home">
            <Hero />
          </section>
          <section id="identity-goals">
            <Helmet>
              <title>Identity Goals - My Awesome React App</title>
              <meta name="description" content="Explore our identity goals." />
              {/* Additional meta tags specific to identity goals section */}
            </Helmet>
            <IdentityGoals />
          </section>
          <section id="services">
            <Helmet>
              <title>Services - My Awesome React App</title>
              <meta name="description" content="Discover our services." />
              {/* Additional meta tags specific to services section */}
            </Helmet>
            <Services />
          </section>
          <section id="technologies">
            <Helmet>
              <title>Technologies - My Awesome React App</title>
              <meta name="description" content="Explore the technologies we use." />
              {/* Additional meta tags specific to technologies section */}
            </Helmet>
            <Technologies />
          </section>
          <section id="clients">
            <Helmet>
              <title>Clients - My Awesome React App</title>
              <meta name="description" content="Meet our clients." />
              {/* Additional meta tags specific to clients section */}
            </Helmet>
            <Clients />
          </section>
          <section id="TrustBuilders">
            <Helmet>
              <title>Trust Builders - My Awesome React App</title>
              <meta name="description" content="Learn about our trust builders." />
              {/* Additional meta tags specific to trust builders section */}
            </Helmet>
            <TrustBuilders />
          </section>
          <section id="team">
            <Helmet>
              <title>Team - My Awesome React App</title>
              <meta name="description" content="Meet our amazing team." />
              {/* Additional meta tags specific to team section */}
            </Helmet>
            <Team />
          </section>
          <section id="contact">
            <Helmet>
              <title>Contact Us - My Awesome React App</title>
              <meta name="description" content="Get in touch with us." />
              {/* Additional meta tags specific to contact form section */}
            </Helmet>
            <ContactForm />
          </section>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
