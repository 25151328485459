import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({ 
    from_name: '', 
    from_email: '', 
    from_phone: '', 
    message: '' 
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus('Sending...');

    emailjs.init("goDbMqgO8OCpBa8T9"); // Replace with your actual public key

    emailjs.send('service_xeydqba', 'template_k0r3w9v', formData)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setStatus('Message sent successfully!');
        setFormData({ 
          from_name: '', 
          from_email: '', 
          from_phone: '', 
          message: '' 
        });
      }, (err) => {
        console.log('FAILED...', err);
        setStatus('Failed to send message. Please try again.');
      });
  };

  return (
    <section className="contact-form">
      <h2>Let's Discuss Your Project</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" name="from_name" value={formData.from_name} onChange={handleChange} placeholder="Name" required />
        <input type="email" name="from_email" value={formData.from_email} onChange={handleChange} placeholder="Email" required />
        <input type="tel" name="from_phone" value={formData.from_phone} onChange={handleChange} placeholder="Phone" required />
        <textarea name="message" value={formData.message} onChange={handleChange} placeholder="Message" required></textarea>
        <button type="submit">Send</button>
      </form>
      {status && <p className="status-message">{status}</p>}
    </section>
  );
};

export default ContactForm;