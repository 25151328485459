import React from 'react';
import './Clients.css';

const ClientCard = ({ logo, title, description }) => (
  <div className="client-card">
    <div className="client-logo-container">
      <img src={logo} alt={title} className="client-logo" />
    </div>
    <h3 className="client-title">{title}</h3>
    <p className="client-description">{description}</p>
  </div>
);

const OurClients = () => {
  const clients = [
  {
    logo: '/images/clients/10dakot.png',
    title: '10 Dakot',
    description: 'Biggest food recipe blog app in Israel with a user-friendly webview interface.'
  },
  {
    logo: '/images/clients/mySong.png',
    title: 'MySong',
    description: 'Innovative music distribution platform for artists and labels.'
  },
  {
    logo: '/images/clients/clubin.png',
    title: 'Clubin',
    description: 'Dynamic community platform connecting people with shared interests.'
  },
  {
    logo: '/images/clients/homeMade.png',
    title: 'HomeMade',
    description: 'Seamless food ordering and delivery service for homemade meals.'
  },
  {
    logo: '/images/clients/momsBack.png',
    title: 'MomsBackToJeans',
    description: 'Personalized diet mentoring program for postpartum mothers.'
  },
  {
    logo: 'images/clients/hatahana.png',
    title: 'Radio Hatahana 101.5',
    description: 'Popular radio station app with live streaming and on-demand content.'
  },
  {
    logo: '/images/clients/radioTzafon.webp',
    title: 'Radio Tzfon 104.5',
    description: 'Northern Israel\'s favorite radio station, now in your pocket.'
  },
  {
    logo: '/images/clients/flightHunter.png',
    title: 'Flight-Hunter',
    description: 'Advanced flight search and booking app with price alerts.'
  },
   {
    logo: '/images/rocket.jpg',
    title: 'client-Supplier Connector',
    description: 'On-demand platform connecting users with various service providers. '
  },
  // {
  //   logo: 'https://images.pexels.com/photos/2159/flight-sky-earth-space.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
  //   title: 'Urban Commute',
  //   description: 'Efficient ride-sharing solution for urban commuters.'
  // },
  {
    logo: '/images/rocket.jpg',
    title: 'Social App',
    description: 'Specialized social network with curated news feed and in-app messaging.'
  },
  {
    logo: '/images/rocket.jpg',
    title: 'Finance app',
    description: 'Comprehensive personal finance management and tracking tool.'
  },
  {
    logo: '/images/rocket.jpg',
    title: 'AI Site Builder',
    description: 'Cutting-edge AI-powered website building platform.'
  },
  {
    logo: '/images/rocket.jpg',
    title: 'Smart Project',
    description: 'Intelligent project management solution powered by AI.'
  }
];

  return (
    <section className="our-clients">
      <div className="clients-container">
        <h2>Our Clients</h2>
        <p>    Explore our portfolio of innovative applications that have made a real impact. 
        From popular food blogs and music platforms to community networks and AI-powered solutions, 
        our diverse range of projects demonstrates our expertise across various domains. 
        While some of our cutting-edge developments are still under wraps, the projects 
        showcased here reflect our commitment to quality, user experience, and client satisfaction. 
        Each application is a testament to our ability to turn unique ideas into successful digital realities.
     </p>
        <div className="client-scroll">
          {clients.map((client, index) => (
            <ClientCard key={index} {...client} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurClients;

