import React from "react";
import "./TrustBuilders.css";
import {
  FaHandshake,
  FaBrain,
  FaLightbulb,
  FaCheckCircle,
  FaCog,
  FaProjectDiagram,
} from "react-icons/fa";
// import { FaChartLine, FaUsers, FaLightbulb, FaCertificate, FaRocket, FaHistory } from 'react-icons/fa';

const TrustItem = ({ icon, title, description }) => (
  <div className="trust-item">
    {icon}
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

const TrustBuilders = () => {
  const trustItems = [
    {
      icon: <FaHandshake className="trust-icon" />,
      title: "Client-Centric Approach",
      description:
        "We prioritize your business needs and simplify the development process, making it easy for you to stay involved and informed.",
    },
    {
      icon: <FaBrain className="trust-icon" />,
      title: "Expert Team",
      description:
        "We carefully select skilled professionals to match your specific project requirements. Our network of experienced developers ensures we can assemble the right team for your unique needs.",
    },
    {
      icon: <FaLightbulb className="trust-icon" />,
      title: "Innovative Solutions",
      description:
        "We leverage cutting-edge technologies and creative thinking to deliver tailored solutions that address your unique business challenges and goals.",
    },
    {
      icon: <FaCheckCircle className="trust-icon" />,
      title: "Proven Track Record",
      description:
        "With 20-30 apps successfully distributed to app stores, we have a history of delivering high-quality applications.",
    },
    {
      icon: <FaCog className="trust-icon" />,
      title: "Business-Oriented Development",
      description:
        "We think beyond the code, focusing on how the product fits into your overall business strategy to ensure it meets your goals.",
    },
    {
      icon: <FaProjectDiagram className="trust-icon" />,
      title: "Our Experience",
      description:
        "With a track record of successful projects across various industries, we bring proven expertise to every challenge.",
    },
  ];
  // const trustItems = [
  //   {
  //     icon: <FaChartLine className="trust-icon" />,
  //     title: "Client Success",
  //     description: "Our solutions have helped clients increase efficiency by an average of 30% and reduce costs by 25%."
  //   },
  //   {
  //     icon: <FaUsers className="trust-icon" />,
  //     title: "Expert Team",
  //     description: "Our team brings over 50 years of combined experience from leading tech companies."
  //   },
  //   {
  //     icon: <FaLightbulb className="trust-icon" />,
  //     title: "Industry Expertise",
  //     description: "We've delivered cutting-edge solutions for Fortune 500 companies across healthcare, finance, and e-commerce sectors."
  //   },
  //   {
  //     icon: <FaCertificate className="trust-icon" />,
  //     title: "Technology Leaders",
  //     description: "We're certified partners with AWS, Google Cloud, and Microsoft Azure."
  //   },
  //   {
  //     icon: <FaRocket className="trust-icon" />,
  //     title: "We Simplify Everything",
  //     description: "Our streamlined process ensures clarity at every step, making complex projects easy to understand and manage."
  //   },
  //   {
  //     icon: <FaHistory className="trust-icon" />,
  //     title: "Our Experience",
  //     description: "With a track record of successful projects across various industries, we bring proven expertise to every challenge."
  //   }
  // ];

  return (
    <section className="trust-builders">
      <h2>Why Choose Us</h2>
      <div className="trust-grid">
        {trustItems.map((item, index) => (
          <TrustItem key={index} {...item} />
        ))}
      </div>
    </section>
  );
};

export default TrustBuilders;
