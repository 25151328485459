import React from 'react';
import './Services.css';
import { FaGlobe,FaRocket, FaMobileAlt, FaPencilRuler, FaStore, FaLightbulb, FaCogs, FaRobot, FaLifeRing } from 'react-icons/fa';

const ServiceCard = ({ icon, title, description }) => (
  <div className="service-card">
    <div className="service-icon">{icon}</div>
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

const Services = () => {
  const services = [
    {
      icon: <FaRocket />,
      title: 'End-to-End Product Development',
      description: 'From concept to launch, we manage the entire development process, making it simple for non-technical clients with clear and engaging progress tracking.'
    },
    {
      icon: <FaMobileAlt />,
      title: 'Mobile App Development',
      description: 'We create native and cross-platform apps for iOS and Android, tailored to your business needs.'
    },
    {
    icon: <FaGlobe />,
    title: 'Webview App Development',
    description: `We convert your website into a fully functional and custom mobile app, reflecting your website's features and design. Our webview apps can be published on app stores, providing a seamless mobile experience for your users.`
  },
    {
      icon: <FaPencilRuler />,
      title: 'UX/UI Design',
      description: 'Our user-centered design approach enhances engagement and satisfaction, transforming complex ideas into intuitive interfaces.'
    },
    {
      icon: <FaStore />,
      title: 'App Store Submission Support',
      description: 'We handle the entire app store submission process for both iOS and Android, ensuring smooth approval and launch.'
    },
    {
      icon: <FaLightbulb />,
      title: 'Product Requirement Analysis',
      description: 'We develop comprehensive user stories and gather requirements to ensure your product meets market needs.'
    },
    {
      icon: <FaCogs />,
      title: 'Project Rescue and Optimization',
      description: 'We revitalize stalled projects with expert analysis, simplification, and strategic redirection for successful completion.'
    },
    {
      icon: <FaRobot />,
      title: 'AI Integration Services',
      description: 'We leverage our AI expertise to seamlessly integrate intelligent solutions into your projects.'
    },
    {
      icon: <FaLifeRing />,
      title: 'Continuous Support and Maintenance',
      description: 'We offer comprehensive post-launch support, including updates, performance optimization, and ongoing technical assistance.'
    }
  ];

  return (
    <section className="services">
      <div className="services-container">
        <h2>Our Comprehensive Services</h2>
        <div className="services-grid">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;