import React from 'react';
import './Technologies.css';
import { FaReact, FaNodeJs, FaPython, FaAws, FaDocker } from 'react-icons/fa';
import { SiJavascript, SiTypescript, SiMongodb, SiGraphql, SiFlutter } from 'react-icons/si';

const TechIcon = ({ name, Icon }) => (
  <div className="tech-icon">
    <Icon />
    <span>{name}</span>
  </div>
);

const Technologies = () => {
  const techStack = [
    { name: 'React', Icon: FaReact },
    { name: 'Node.js', Icon: FaNodeJs },
    { name: 'Python', Icon: FaPython },
    { name: 'JavaScript', Icon: SiJavascript },
    // { name: 'TypeScript', Icon: SiTypescript },
    { name: 'AWS', Icon: FaAws },
    { name: 'Docker', Icon: FaDocker },
    { name: 'MongoDB', Icon: SiMongodb },
    { name: 'Flutter', Icon: SiFlutter }
  ];

  return (
    <section className="technologies">
      <div className="tech-container">
        <h2>Technologies We Work With</h2>
        <p>Our team is proficient in a wide range of cutting-edge technologies to deliver the best solutions for your projects.</p>
        <div className="tech-grid">
          {techStack.map((tech, index) => (
            <TechIcon key={index} name={tech.name} Icon={tech.Icon} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Technologies;