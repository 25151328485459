// Footer.js

import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="company-info">
          <h3>CHAGIL OÜ</h3>
          <p>Simplifying Innovation</p>
        </div>
        <div className="contact-info">
          <p>Linda 2</p>
          <p>Narva, Estonia</p>
          <p>Email: gil@chagil.com</p>
          <p>Phone: +372 57836389</p>
        </div>
        <div className="legal-info">
          <p>&copy; 2024 CHAGIL OÜ All rights reserved.</p>
          <p>Registration No: 16360108</p>
          <p>VAT ID: EE102506606</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;