import React from 'react';
import './IdentityGoals.css';

const IdentityAndGoals = () => {
  return (
    <section className="identity-and-goals">
      <div className="content-wrapper">
        <div className="text-content">
          <h2>Our Identity and Goals</h2>
          <p>
            Chagil Apps was born from a deep understanding of business needs. Our approach is unique: we prioritize business requirements first, then focus on product design, and only then move to development and technology. This client-centric philosophy ensures that every solution we create is tailored to real-world demands.
          </p>
          <p>
            With years of experience in helping startups and businesses bring their ideas to life, we deliver seamless, user-friendly digital solutions that drive innovation and success. Our commitment is to transform complex ideas into simple, effective applications.
          </p>
          <h3 className="slogan">Simplicity in Every Step: From Concept to Code</h3>
        </div>
        <div className="illustration">
          <img src="/images/goals.svg" alt="Our Goals Illustration" className="goals-image" />
        </div>
      </div>
    </section>
  );
};

export default IdentityAndGoals;