import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-content">
        <h1>We Code Your Imagination</h1>
        <p id="subTitle">At Chagil Apps, we simplify app development.<br/>
  Confused where to start? Overwhelmed by tech talk?<br/>
  We guide you from concept to launch.<br/>
  Our experts turn your vision into reality.<br/>
  Smooth process, on-time delivery, within budget.<br/>
  Let's bring your app idea to life – hassle-free.
</p>
         <a href="#contact"><button className="cta-button">Describe Your Idea to Us</button></a>
      </div>
      <div className="hero-image">
       <img src='images/heroImage.svg' alt="Hero Illustration" className="hero-illustration" />
      </div>
    </section>
  );
};

export default Hero;
